import React from "react"
import PropTypes from "prop-types"
import EnsLink from "../EnsLink/EnsLink"
import { GatsbyImage } from "gatsby-plugin-image"
import "./VideoPanel.scss"

const VideoPanel = ({ title, content, date, link, externalLink, image }) => {

  return (
    <article className="video-item">
     {title && (
        <EnsLink link={{ url: content ? link : externalLink}}>
          {image && (
            <div className="video-image">
              <div className="video-tags">
              </div>
              <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData}
                          alt={image?.altText} 
                          className="video-image-container"
                          loading="lazy" />
            </div>
          )}
          <p className="video-title h4">{title}</p>
          <div>
            {date && <span className="video-date"> {date}</span>}
          </div>
        </EnsLink>
      )}
    </article>
  )
}

VideoPanel.defaultProps = {
  tags: [],
}

VideoPanel.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
export default VideoPanel
