import React from "react"
import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"
import { navigate } from "gatsby"
import "./VideoPagination.scss"

const VideoPagination = ({ currentPage, totalPages, pagePath }) => {
  return (
    <section className="video-pagination">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pagination">
              {totalPages && totalPages > 1 && (
                <ReactPaginate
                  pageCount={totalPages}
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={false}
                  forcePage={currentPage - 1}
                  className="pagination-component"
                  onPageChange={p =>
                    navigate(
                      `${pagePath || "/librarie-video-ensys/"}${
                        p.selected > totalPages || p.selected === 0
                          ? ""
                          : `/${p.selected + 1}`
                      }`
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

VideoPagination.defaultProps = {
  pagePath: "/librarie-video-ensys",
}

VideoPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pagePath: PropTypes.string,
}

export default VideoPagination
