import React from "react"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import VideoPanel from "../VideoPanel/VideoPanel"
import EnsLink from "../EnsLink/EnsLink"
import EnsButton from "../EnsButton/EnsButton"
import { useHomepageVideoLibrarySection } from "../../hooks/HomePage/useHomepageVideoLibrarySection"
import "./VideoPageListing.scss"
import { useTranslation } from "react-i18next"

const VideoPageListing = ({ data, location }) => {
  const { title, youtubeButton, viewMoreLink, viewMore } = useHomepageVideoLibrarySection();
  const { t } = useTranslation()

  return (
    <section className={`video-section ${location === 'homepage' ? 'homepage-component' : ''}`}>
      <div className="container">
        <div className="title">
          {title && title.trim() !== '' ? (
            <h2>{title}</h2>
            ) : (
            <h2>{t("Videoclipuri Ensys")}</h2>
          )}
          <div className="subscribe-button">
          <EnsLink link={{ url: youtubeButton && youtubeButton.trim() !== '' ? youtubeButton : 'https://www.youtube.com/@Ensys-renew/videos' }}>
            <EnsButton variant="primary">{t("Abonează-te")}</EnsButton>
          </EnsLink>
          </div>
        </div>
        {location && location !== 'homepage' && (
          <hr className="divider" />
        )}
        <div className="row">
          {data.map(video => (
            <div className="col-md-4" key={uniqueId("_article")}>
              <VideoPanel
                title={video.title}
                content={video.content}
                date={video.date}
                link={video.link}
                externalLink = {video.postExtraData?.dataAboutPost.url}
                image = {video.postExtraData?.dataAboutPost?.image}
                />
            </div>
          ))}
        </div>
        {location && location === 'homepage' && (
          <EnsLink link={{ url: viewMoreLink && viewMoreLink.trim() !== '' ? viewMoreLink : '/librarie-video-ensys' }}>
            <EnsButton className="view-more-btn" variant="ghost-link">
              { viewMore && viewMore.trim() !== '' ? viewMore : t("Vezi mai multe") }
            </EnsButton>
          </EnsLink>
        )}
      </div>
    </section>
  )
}

VideoPageListing.defaultProps = {
  data: [],
}

VideoPageListing.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

export default VideoPageListing
