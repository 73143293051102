import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useHomepageVideoLibrarySection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const { wpPageRo, wpPageEn } = useStaticQuery(graphql`
  {
    wpPageRo: wpPage(
      template: { templateName: { eq: "Homepage" } }
      language: { code: { eq: RO } }
    ) {
      homePageFields {
        videoLibrary {
          title
          youtubeButton
          viewMoreLink
          viewMore
        }
      }
    }

    wpPageEn: wpPage(
      template: { templateName: { eq: "Homepage" } }
      language: { code: { eq: EN } }
    ) {
      homePageFields {
        videoLibrary {
          title
          youtubeButton
          viewMoreLink
          viewMore
        }
      }
    }
  }
`)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.homePageFields?.videoLibrary ||
    wpPageRo?.homePageFields?.videoLibrary
  )
}
