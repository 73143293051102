/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import VideoPageListing from "../components/VideoPageListing/VideoPageListing"
import VideoPagination from "../components/VideoPagination/VideoPagination"
import SeoPage from "../components/seo-page"

const VideoIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, totalPages, currentPage },
}) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    title: {
      en: "Videos - Ensys",
      ro: "Videoclipuri - Ensys",
    },
    description: {
      en: "Your source for solar technology news and information. Watch our latest videos to learn about solar panel installation, industry innovations and helpful tips.",
      ro: "Sursa ta de informații și noutăți privind tehnologia solară. Urmărește ultimele noastre videoclipuri pentru a te familiariza cu instalarea panourilor solare, inovațiile în domeniu și sfaturi utile.",
    },
    keyword: {
      en: "Videos, Ensys Company",
      ro: "Videoclipuri, compania Ensys",
    },
    image: {
      en: "https://wp.ensys.com.ro/wp-content/uploads/2023/03/ENS-OG-IMAGE.png",
      ro: "https://wp.ensys.com.ro/wp-content/uploads/2023/03/ENS-OG-IMAGE.png",
    },
  }
  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  const videos = {
    en: data.allWpVideoEn,
    ro: data.allWpVideoRo,
  }
  const filteredVideos = videos[language].nodes

  if (!filteredVideos.length) {
    return (
      <Layout isHomePage>
        <SeoPage
          title={getSeoFields("title")}
          description={getSeoFields("description")}
          keyword={getSeoFields("keyword")}
          image={getSeoFields("image")}
          slug={filteredVideos.slug}
        />
        <Header />
        <p style={{ marginTop: "5rem", marginBottom: "10rem" }}>
          Momentan nu sunt regelementări de afișat pe această pagină.
        </p>
        <Footer />
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <SeoPage
        title={getSeoFields("title")}
        description={getSeoFields("description")}
        keyword={getSeoFields("keyword")}
        image={getSeoFields("image")}
        slug={filteredVideos.slug}
      />
      <Header />
      <VideoPageListing data={filteredVideos} location="videos" />
      <VideoPagination
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <Footer />
    </Layout>
  )
}

export default VideoIndex

export const pageQuery = graphql`
  query WordPressVideosArchive($offset: Int!, $postsPerPage: Int!) {
    allWpVideoRo: allWpVideo(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: { language: { code: { eq: RO } } }
    ) {
      nodes {
        id
        title
        content
        date(formatString: "MMMM DD, YYYY")
        link
        postExtraData {
          dataAboutPost {
            url
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    allWpVideoEn: allWpVideo(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: { language: { code: { eq: EN } } }
    ) {
      nodes {
        id
        title
        content
        date(formatString: "MMMM DD, YYYY")
        link
        postExtraData {
          dataAboutPost {
            url
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
